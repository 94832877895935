<template>
  <div class="grid">
    <Toast />

    <div class="col-12" v-if="produtoData && loadingData === false">
      <h6 v-if="produtoData.id">Editando Produto {{ produtoData.id}}
      <span :class="'ml-3  produto-badge status-' + (produtoData.status ? produtoData.status.toLowerCase() : '')">{{ nomeStatus(produtoData.status) }}</span>
      </h6>
      <h6 v-else>Novo Produto</h6>
      <Toolbar class="mb-4">
        <template v-slot:start>
          <Button label="Exportar" icon="pi pi-download" class="mr-2 p-button-outlined" @click="exportCSV($event)" v-if="userData && userData.tipo === 'admin'"/>
          <Button label="Salvar" icon="pi pi-save" class="p-button-success mr-2" @click="saveProduto" :loading="loadingBtnSave" :disabled="true" v-if="userData && userData.tipo === 'admin'"/>
          <FileUpload mode="basic" accept=".pdf" :maxFileSize="1000000" label="Importar" chooseLabel="Importar" class="mr-2 p-button-outlined" :disabled="true" v-if="userData && userData.tipo === 'admin'"/>
          <Button label="Nova Ocorrência" icon="pi pi-plus" class="mr-2 p-button-outlined" @click="novaOcor()" :disabled="true" />
        </template>
        <template v-slot:end>
          <Button label="Voltar" icon="pi pi-backward" class="p-button-outlined mr-2" @click="$router.go(-1)" />
        </template>
      </Toolbar>
    </div>

    <div class="col-12" >

      <div  class="custom-skeleton p-4" v-if="loadingData === true">
        <Skeleton width="100%" height="50px"></Skeleton>
        <Skeleton width="100%" height="50px" class="mt-3"></Skeleton>
        <Skeleton width="100%" height="50px" class="mt-3"></Skeleton>
        <Skeleton width="100%" height="50px" class="mt-3"></Skeleton>
      </div>

      <TabView v-if="loadingData === false" :activeIndex="activeIndex">

        <TabPanel>

          <template #header>
            <span>Dados Principais</span>
            <i class="pi pi-box ml-2"></i>
          </template>

          <div class="p-fluid formgrid grid">

            <div class="field col-3 md:col-3">
              <label for="codexterno">Código</label>
              <InputText id="codexterno"
                         type="text"
                         :class="validProduto && !produtoData.codexterno ? 'p-invalid' : ''"
                         v-model="produtoData.codexterno"/>
              <small class="p-invalid" v-if="validProduto && !produtoData.codexterno">Campo obrigatório.</small>
            </div>

            <div class="field col-3 md:col-3">
              <label for="descricao">Descrição</label>
              <InputText id="descricao"
                         type="text"
                         :class="validProduto && !produtoData.descricao ? 'p-invalid' : ''"
                         v-model="produtoData.descricao"/>
              <small class="p-invalid" v-if="validProduto && !produtoData.descricao">Campo obrigatório.</small>
            </div>

            <div class="field col-3 md:col-3">
              <label for="status">Status</label>
              <Dropdown id="status"
                        v-model="produtoData.status"
                        :options="statuses"
                        optionLabel="name"
                        optionValue="code"
                        placeholder="Selecione o Status">
                <template #value="slotProps">
                  <div v-if="slotProps.value && slotProps.value.value">
                    <span :class="'produto-badge status-' +slotProps.value.value"> {{ nomeStatus(slotProps.value.value) }}</span>
                  </div>
                  <div v-else-if="slotProps.value && !slotProps.value.value">
                    <span :class="'produto-badge status-' +slotProps.value.toLowerCase()">{{ nomeStatus(slotProps.value) }}</span>
                  </div>
                  <span v-else>
									{{slotProps.placeholder}}
								</span>
                </template>
              </Dropdown>
            </div>

            <div class="field col-3 md:col-3">
              <label for="grupo">Grupo</label>
              <InputText id="grupo" type="text" v-model="produtoData.grupo"/>
            </div>

            <div class="field col-3 md:col-3">
              <label for="subgrupo">Subgrupo</label>
              <InputText id="subgrupo" type="text" v-model="produtoData.subgrupo"/>
            </div>

            <div class="field col-3 md:col-3">
              <label for="secao">Seção</label>
              <InputText id="secao" type="text" v-model="produtoData.secao"/>
            </div>

            <div class="field col-3 md:col-3">
              <label for="codaux">Código Auxiliar</label>
              <InputText id="codaux" type="text" v-model="produtoData.codaux"/>
            </div>

            <div class="field col-3 md:col-3">
              <label for="fabricante">Fabricante</label>
              <InputText id="codaux" type="text" v-model="produtoData.fabricante"/>
            </div>

            <div class="col-12" >
              <h5>Integração Logística</h5>
            </div>

            <div class="field col-3 md:col-3">
              <label for="pesobrt">Peso Bruto</label>
              <InputNumber id="pesobrt" v-model="produtoData.pesobrt" mode="decimal" locale="pt-BR" :minFractionDigits="2"/>
            </div>

            <div class="field col-3 md:col-3">
              <label for="pesoliq">Peso Líquido</label>
              <InputNumber id="pesoliq" v-model="produtoData.pesoliq" mode="decimal" locale="pt-BR" :minFractionDigits="2"/>
            </div>

            <div class="field col-3 md:col-3">
              <label for="saldo">Saldo</label>
              <InputNumber id="saldo" v-model="produtoData.saldo" mode="decimal" locale="pt-BR" :minFractionDigits="2"/>
            </div>

            <div class="col-12" >
              <h5>Tributação</h5>
            </div>

            <div class="field col-3 md:col-3">
              <label for="icms">ICMS</label>
              <InputNumber id="icms" v-model="produtoData.icms" mode="decimal" locale="pt-BR" :minFractionDigits="2"/>
            </div>

            <div class="field col-3 md:col-3">
              <label for="ipi">IPI</label>
              <InputNumber id="ipi" v-model="produtoData.ipi" mode="decimal" locale="pt-BR" :minFractionDigits="2"/>
            </div>

            <div class="field col-3 md:col-3">
              <label for="icmsst">ICMSST</label>
              <InputNumber id="icmsst" v-model="produtoData.icmsst" mode="decimal" locale="pt-BR" :minFractionDigits="2"/>
            </div>

            <div class="field col-12 md:col-12">
              <label for="obs">Observações</label>
              <Textarea id="obs" rows="4" v-model="produtoData.obs"/>
            </div>


          </div>
        </TabPanel>

        <TabPanel>
          <template #header>
            <span>Tabelas de Preços</span>
            <i class="pi pi-money-bill ml-2"></i>
          </template>
        </TabPanel>

        <TabPanel>

          <template #header>
            <span>Anexos</span>
            <i class="pi pi-paperclip ml-2"></i>
          </template>

          <div class="p-fluid formgrid grid" v-if="produtocadData">

            <Divider layout="horizontal">
              <b>Anexos</b>
              <i class="pi pi-map ml-2"></i>
            </Divider>

            <div class="field col-12 md:col-3">
              <h6>Foto Produto (2000 x 2000 px)</h6>
              <FileUpload @click="arqSubindo = 'relogio'" :multiple="false" @before-send="beforeUpload" :url="baseUrl" choose-label="Escolher arquivo" upload-label="Upload Servidor" cancel-label="Cancelar" mode="basic" name="arquivo" accept="image/*, application/pdf" :maxFileSize="1000000" @upload="onUpload" style="width: 100%" />
              <Button label="Excluir" icon="pi pi-trash" class="p-button-secondary p-button-outlined" v-if="arqRelogio" @click="excluirFile(arqRelogio)" style="width: 50%;"/>
              <Button label="Download" icon="pi pi-download" class="p-button-secondary" v-if="arqRelogio" @click="downloadFile(arqRelogio.url)" style="width: 50%;"/>
            </div>

            <div class="field col-12 md:col-3">
              <h6>Foto Produto (200 x 200 px)</h6>
              <FileUpload @click="arqSubindo = 'quadro_distribuicao'" :multiple="false" @before-send="beforeUpload" :url="baseUrl" choose-label="Escolher arquivo" upload-label="Upload Servidor" cancel-label="Cancelar" mode="basic" name="arquivo" accept="image/*, application/pdf" :maxFileSize="1000000" @upload="onUpload" style="width: 100%" />
              <Button label="Excluir" icon="pi pi-trash" class="p-button-secondary p-button-outlined" v-if="arqQuadroDistribuicao" @click="excluirFile(arqQuadroDistribuicao)" style="width: 50%;"/>
              <Button label="Download" icon="pi pi-download" class="p-button-secondary" v-if="arqQuadroDistribuicao" @click="downloadFile(arqQuadroDistribuicao.url)" style="width: 50%;"/>
            </div>

            <div class="field col-12 md:col-3">
              <h6>Vídeo Marketing</h6>
              <FileUpload @click="arqSubindo = 'disjuntor_geral'" :multiple="false" @before-send="beforeUpload" :url="baseUrl" choose-label="Escolher arquivo" upload-label="Upload Servidor" cancel-label="Cancelar" mode="basic" name="arquivo" accept="image/*, application/pdf" :maxFileSize="1000000" @upload="onUpload" style="width: 100%" />
              <Button label="Excluir" icon="pi pi-trash" class="p-button-secondary p-button-outlined" v-if="arqDisjuntorGeral" @click="excluirFile(arqDisjuntorGeral)" style="width: 50%;"/>
              <Button label="Download" icon="pi pi-download" class="p-button-secondary" v-if="arqDisjuntorGeral" @click="downloadFile(arqDisjuntorGeral.url)" style="width: 50%;"/>
            </div>


            <div class="field col-12 md:col-3">
              <h6>PDF Marketing</h6>
              <FileUpload @click="arqSubindo = 'conta_energia'" :multiple="false" @before-send="beforeUpload" :url="baseUrl" choose-label="Escolher arquivo" upload-label="Upload Servidor" cancel-label="Cancelar" mode="basic" name="arquivo" accept="image/*, application/pdf" :maxFileSize="1000000" @upload="onUpload" style="width: 100%" />
              <Button label="Excluir" icon="pi pi-trash" class="p-button-secondary p-button-outlined" v-if="arqContaEnergia" @click="excluirFile(arqContaEnergia)"  style="width: 50%;"/>
              <Button label="Download" icon="pi pi-download" class="p-button-secondary" v-if="arqContaEnergia" @click="downloadFile(arqContaEnergia.url)"  style="width: 50%;"/>
            </div>

          </div>

        </TabPanel>



        <TabPanel>

          <template #header>
            <span>Ocorrências</span>
            <i class="pi pi-exclamation-circle ml-2"></i>
          </template>

          <DataTable v-if="ocorsData" :value="ocorsData" :scrollable="true" scrollHeight="400px" :loading="loadingOcors" scrollDirection="both" class="mt-3">
            <Column header="Ações" class="text-right" :style="{width:'100px'}" >
              <template #body="slotProps">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="abrirOcor(slotProps.data.id)" />
              </template>
            </Column>
            <Column field="id" header="Código" :style="{width:'100px'}" ></Column>
            <Column header="Data" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.created_at)}}
              </template>
            </Column>
<!--            <Column field="tipo" header="Tipo" :style="{width:'200px'}"></Column>-->

            <Column field="tipo" header="Tipo" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'cliocor-badge tipo-' + (data.tipo ? data.tipo.toLowerCase() : '')">{{data.tipo}}</span>
              </template>
            </Column>

            <Column field="obs" header="Observação" :style="{width:'300px'}" ></Column>

            <Column field="status" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'cliocor-badge status-' + (data.status ? data.status.toLowerCase() : '')"> {{ data.status }} </span>
              </template>
            </Column>
            <Column field="rating" header="Visualizações" :sortable="true">
              <template #body="slotProps">
                <span class="p-column-title">Rating</span>
                <Rating :modelValue="slotProps.data.rating" :readonly="true" :cancel="false" />
              </template>
            </Column>


          </DataTable>


        </TabPanel>


        <TabPanel>

          <template #header>
            <span>Leads</span>
            <i class="pi pi-id-card ml-2"></i>
          </template>

          <DataTable v-if="leadsData" :value="leadsData" :scrollable="true" scrollHeight="400px" :loading="loadingLeads" scrollDirection="both" class="mt-3">
            <Column header="Ações" class="text-right" :style="{width:'100px'}" >
              <template #body="">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="$router.push('/leads')" />
              </template>
            </Column>
            <Column field="code" header="Código" :style="{width:'100px'}" ></Column>
            <Column header="Data" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>
            </Column>
            <Column field="representante" header="Representante" :style="{width:'200px'}"></Column>
            <Column field="inventoryStatus" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'lead-badge status-' + (data.inventoryStatus ? data.inventoryStatus.toLowerCase() : '')">{{data.inventoryStatus}}</span>
              </template>
            </Column>
            <Column field="rating" header="Visualizações" :sortable="true">
              <template #body="slotProps">
                <span class="p-column-title">Rating</span>
                <Rating :modelValue="slotProps.data.rating" :readonly="true" :cancel="false" />
              </template>
            </Column>
            <Column field="price" header="Valor" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatCurrency(data.price)}}</span>
              </template>
            </Column>

          </DataTable>


        </TabPanel>

        <TabPanel>
          <template #header>
            <span>Orçamentos</span>
            <i class="pi pi-eye ml-2"></i>
          </template>

          <DataTable v-if="orcamentosData" :value="orcamentosData" :scrollable="true" scrollHeight="400px" :loading="loadingLeads" scrollDirection="both" class="mt-3">
            <Column header="Ações" class="text-right" :style="{width:'100px'}" >
              <template #body="">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="$router.push('/orcamentodetalhe')" />
              </template>
            </Column>
            <Column field="id" header="Código" :style="{width:'100px'}" ></Column>
            <Column header="Data" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>
            </Column>
            <Column header="Representante" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.representative.name}}
              </template>
            </Column>
            <Column header="Cidade" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.cidade}}
              </template>
            </Column>
            <Column header="Estado" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.estado}}
              </template>
            </Column>
            <Column field="status" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'orcamento-badge status-' + (data.status ? data.status.toLowerCase() : '')">{{data.status}}</span>
              </template>
            </Column>
            <Column field="price" header="Valor" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatCurrency(data.balance)}}</span>
              </template>
            </Column>

          </DataTable>

        </TabPanel>


        <TabPanel>
          <template #header>
            <span>Vendas</span>
            <i class="pi pi-shopping-cart ml-2"></i>
          </template>

          <DataTable v-if="pedidosData" :value="pedidosData" :scrollable="true" scrollHeight="400px" :loading="loadingLeads" scrollDirection="both" class="mt-3">
            <Column header="Ações" class="text-right" :style="{width:'100px'}" >
              <template #body="">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="$router.push('/pedidodetalhe')" />
              </template>
            </Column>
            <Column field="id" header="Código" :style="{width:'100px'}" ></Column>
            <Column header="Data" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>
            </Column>
            <Column header="Representante" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.representative.name}}
              </template>
            </Column>
            <Column header="Cidade" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.cidade}}
              </template>
            </Column>
            <Column header="Estado" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.estado}}
              </template>
            </Column>
            <Column field="status" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'pedido-badge status-' + (data.status ? data.status.toLowerCase() : '')">{{data.status}}</span>
              </template>
            </Column>
            <Column field="price" header="Valor" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatCurrency(data.balance)}}</span>
              </template>
            </Column>
          </DataTable>

        </TabPanel>


        <TabPanel>
          <template #header>
            <span>Financeiro</span>
            <i class="pi pi-money-bill ml-2"></i>
          </template>

        </TabPanel>


      </TabView>
    </div>


    <Sidebar v-model:visible="sidebarOcorrencia" :baseZIndex="1000" position="right" class="p-sidebar-md">
      <h5 v-if="!ocorrenciaData.id">Nova Ocorrência</h5>
      <h5 v-if="ocorrenciaData.id">Editando Ocorrência {{ ocorrenciaData.id }}</h5>


      <div class="grid p-fluid mt-3" >
        <div class="col-12 md:col-12">
          <label for="tipoocor">Tipo</label>
          <div class="p-inputgroup">

            <Dropdown id="tipoocor"
                      v-model="ocorrenciaData.tipo"
                      :options="tipoocorItems"
                      optionLabel="name"
                      optionValue="code"
                      :class="validOcor && !ocorrenciaData.tipo ? 'p-invalid' : ''"
                      placeholder="Selecione o tipo">
            </Dropdown>
            <small class="p-invalid" v-if="validOcor && !ocorrenciaData.tipo">Campo obrigatório.</small>

          </div>
        </div>

        <div class="col-12 md:col-12" >
          <label for="obsocor">Observações</label>
          <div class="p-inputgroup">

            <Textarea id="obsocor"
                      rows="4"
                      :class="validOcor && !ocorrenciaData.obs ? 'p-invalid' : ''"
                      v-model="ocorrenciaData.obs" />

            <small class="p-invalid" v-if="validOcor && !ocorrenciaData.obs">Campo obrigatório.</small>
          </div>
        </div>

        <div class="col-6 md:col-6" v-if="ocorrenciaData.id">
          <Button label="Excluir" icon="pi pi-trash" class="mr-2 p-button-danger p-button-outlined" @click="excluirOcor()"  />
        </div>

        <div class="col-6 md:col-6" >
          <Button label="Fechar" icon="pi pi-ban" class="mr-2 p-button-outlined" @click="sidebarOcorrencia = false; validOcor = false;"  />
        </div>

        <div class="col-6 md:col-6" >
          <Button label="Salvar" icon="pi pi-save" class="mr-2 p-button-outlined" @click="saveOcorrencia()" :loading="loadingBtnSaveOcor" />
        </div>




      </div>
    </Sidebar>


    <ConfirmDialog group="dialog" />



  </div>
</template>
<script>
import ProdutoService from "@/service/ProdutoService";
import api from "@/axios";

export default {
  components: {

  },
  data() {
    return {
      userData: null,
      validProduto: false,

      activeIndex: 0,
      loadingBtnSaveOcor: false,
      validOcor: false,
      sidebarOcorrencia: false,
      ocorrenciaData: {},

      arqSubindo: '',
      arqFaixada: null,
      arqTelhado: null,
      arqDisjuntorGeral: null,
      arqQuadroDistribuicao: null,
      arqRelogio: null,
      arqContaEnergia: null,
      arquivos: [],
      tipoUpload: '',
      baseUrl: '',

      statuses: [
        {name: 'ATIVO', code: 'A'},
        {name: 'INATIVO', code: 'I'},
      ],

      tipoocorItems: [
        {code: 'AV', name: 'Aviso'},
        {code: 'CO', name: 'Confirmação'},
        {code: 'PR', name: 'Problema'},
      ],

      telhorieItems: [
        {code: 'NO', name: 'Norte'},
        {code: 'SU', name: 'Sul'},
        {code: 'LE', name: 'Leste'},
        {code: 'OE', name: 'Oeste'},
        {code: 'ND', name: 'Nordeste'},
        {code: 'SD', name: 'Sudoeste'},
        {code: 'NR', name: 'Noroeste'},

      ],
      geradorItems: [
        {code: 'SIM', name: 'Sim'},
        {code: 'NAO', name: 'Não'},

      ],
      telhestrItems: [
        {code: 'MAD', name: 'Madeira'},
        {code: 'MET', name: 'Metal'},
        {code: 'TER', name: 'Terça'},
        {code: 'OUT', name: 'Outro'},
      ],
      telhadoItems: [
        {code: 'FIB', name: 'Fibrocimento'},
        {code: 'COL', name: 'Colonial'},
        {code: 'ZIN', name: 'Zinco'},
        {code: 'OUT', name: 'Outro'},
      ],
      tensaoItems: [
        {code: '127', name: '127V'},
        {code: '220', name: '220V'},
        {code: '380', name: '380V'},
      ],
      sistemaItems: [
        {code: 'MN', name: 'Monofásico'},
        {code: 'BI', name: 'Bifásico'},
        {code: 'TR', name: 'Trifásico'},
      ],
      radioValueEspaco: null,
      radioValueAumento: null,
      radioValueInternet: null,
      radioValueGrupo: null,
      produtocadData: {
        'perfil': 'coisa'
      },

      loadingCEP: false,
      loadingData: false,
      loadingBtnSave: false,
      loadingBtn: [false, false, false],
      pedidosData: null,
      orcamentosData: null,
      obrasData: null,
      projetosData: null,

      loadingOcors: null,
      ocorsData: null,

      loadingLeads: true,
      leadService: null,
      leadsData: null,
      produtoData: {
        'id': 0,
        'tipo': 'PJ',
        'status': 'EL'
      },

      tipoItems: [
        {code: 'PF', name: 'Pessoa Física'},
        {code: 'PJ', name: 'Pessoa Jurídica'},
      ],

      estadoItems: [
        {code: 'AC', name: 'Acre'},
        {code: 'AL', name: 'Alagoas'},
        {code: 'AP', name: 'Amapá'},
        {code: 'AM', name: 'Amazonas'},
        {code: 'BA', name: 'Bahia'},
        {code: 'CE', name: 'Ceará'},
        {code: 'DF', name: 'Distrito Federal'},
        {code: 'ES', name: 'Espírito Santo'},
        {code: 'GO', name: 'Goiás'},
        {code: 'MA', name: 'Maranhão'},
        {code: 'MT', name: 'Mato Grosso'},
        {code: 'MS', name: 'Mato Grosso do Sul'},
        {code: 'MG', name: 'Minas Gerais'},
        {code: 'PA', name: 'Pará'},
        {code: 'PB', name: 'Paraíba'},
        {code: 'PR', name: 'Paraná'},
        {code: 'PE', name: 'Pernambuco'},
        {code: 'PI', name: 'Piauí'},
        {code: 'RJ', name: 'Rio de Janeiro'},
        {code: 'RN', name: 'Rio Grande do Norte'},
        {code: 'RS', name: 'Rio Grande do Sul'},
        {code: 'RO', name: 'Rondônia'},
        {code: 'RR', name: 'Roraima'},
        {code: 'SC', name: 'Santa Catarina'},
        {code: 'SP', name: 'São Paulo'},
        {code: 'SE', name: 'Sergipe'},
        {code: 'TO', name: 'Tocantins'},
      ],
      dropdownItem: null,
    }
  },

  created() {
    this.produtoService = new ProdutoService();

  },


  mounted() {
    console.log('mounted clidetalhe')
    console.log(api.getUri())
    this.baseUrl = api.getUri() + 'api/ssuploadfile'
    let idproduto = this.$route.params.id

    if (idproduto > 0){
      this.loadingData = true
      this.produtoService.getProduto(idproduto).then((data) => {
        this.userData = data[0]
        this.produtoData = data[1]
        this.ocorrenciaData.pessoa_id = this.produtoData.id

        console.log('getProduto')
        console.log(data)

        if (data === 'nao_permitido') {
          this.$router.replace({ path: '/acessorestrito' })
        }else{
          this.loadingData = false
        }
      })

      // this.getOcorrencias(idproduto)

    }

  },

  methods: {

    nomeStatus(sigla){
      switch (sigla) {
        case 'A':
          return 'Ativo'
        case 'I':
          return 'Inativo'
        case 'E':
          return 'Elaboração'
        default:
          return 'Não Definido'
      }
    },

    beforeUpload(){

    },

    getOcorrencias(idproduto){
      this.produtoService.getsscliocors(idproduto).then((data) => {
        console.log('this.ocorsData')
        this.ocorsData = data
        this.loadingOcors = false
        console.log(this.ocorsData)
        this.ocorsData.forEach(ocor => ocor.created_at = new Date(ocor.created_at));
      });
    },

    excluirOcor(){
      console.log('excluirOcor')

      this.$confirm.require({
        group: 'dialog',
        header: 'Confirmação',
        message: 'Tem certeza que deseja deletar o registro?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: () => {
          this.produtoService.ssdeleteocor(this.ocorrenciaData.id).then(() => {
            this.$toast.add({severity:'success', summary: 'Registro excluído', detail:'As informações foram excluídas com sucesso', life: 3000});
            this.sidebarOcorrencia = false
            this.getOcorrencias(this.produtoData.id)
          })
        },
        reject: () => {

        }
      });


    },

    abrirOcor(idOcor){
      console.log('abrirOcor')
      console.log(idOcor)
      this.produtoService.getsscliocor(idOcor).then((data) => {
        console.log('this.ocorData')
        //console.log(data[0])
        this.ocorrenciaData = data[0]
        this.sidebarOcorrencia = true
      });
    },

    saveOcorrencia() {
      console.log('sssaveocor')
      // console.log(this.ocorrenciaData)
      this.validOcor = true
      this.loadingBtnSaveOcor = true
      if (this.ocorrenciaData.tipo && this.ocorrenciaData.obs){
        this.produtoService.savessocor(this.ocorrenciaData).then(() => {
          // console.log('savessocor')
          // console.log(dataOcor)
          this.loadingBtnSaveOcor = false
          this.sidebarOcorrencia = false
          this.$toast.add({severity:'success', summary: 'Registro atualizado', detail:'As informações foram atualizadas com sucesso', life: 3000});


          this.getOcorrencias(this.produtoData.id)
          // this.produtoService.getsscliocors(this.produtoData.id).then((data) => {
          //   console.log('this.ocorsData')
          //   this.ocorsData = data
          //   this.loadingOcors = false
          //   console.log(this.ocorsData)
          //   this.ocorsData.forEach(ocor => ocor.created_at = new Date(ocor.created_at));
          // });

        });
      }else{
        this.loadingBtnSaveOcor = false
      }
    },

    novaOcor(){
      console.log('novaOcor')
      this.ocorrenciaData = {
        'pessoa_id': this.produtoData.id
      }
      this.validOcor = false
      this.sidebarOcorrencia = true
      this.activeIndex = 2

    },

    excluirFile(arquivo){
      console.log('excluirFile')
      console.log(arquivo.id)

      this.$confirm.require({
        group: 'dialog',
        header: 'Confirmação',
        message: 'Tem certeza que deseja deletar o arquivo?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: () => {
          console.log('confirmou')

          this.produtoService.ssdeletefile(arquivo.id).then((data) => {
            console.log('data delete file')
            console.log(data)


            if (arquivo.tipo === 'conta_energia'){
              this.arqContaEnergia = null
            } else if (arquivo.tipo === 'relogio'){
              this.arqRelogio = null
            } else if (arquivo.tipo === 'quadro_distribuicao'){
              this.arqQuadroDistribuicao = null
            } else if (arquivo.tipo === 'disjuntor_geral'){
              this.arqDisjuntorGeral = null
            } else if (arquivo.tipo === 'telhado'){
              this.arqTelhado = null
            } else if (arquivo.tipo === 'faixada'){
              this.arqFaixada = null
            }


            this.$toast.add({severity:'success', summary: 'Arquivo excluído', detail:'As informações foram excluídas com sucesso', life: 3000});

          })


        },
        reject: () => {

        }
      });
    },


    downloadFile(arquivo){
      console.log('downloadFile')
      console.log(arquivo)

      window.open(api.getUri() + 'uploads/'+ arquivo, '_blank');
    },

    onUpload() {
      console.log('onUpload')
      this.atualizaListaImages()
      this.$toast.add({severity:'success', summary: 'Arquivo gravado', detail:'As informações foram atualizadas com sucesso', life: 3000});

    },

    selecionaCidade(event){
      console.log('selecionaProduto')
      // console.log(event.value)
      this.produtoData['cidade'] = event.value.descricao
      this.produtoData['estado'] = event.value.estado
    },

    searchCidade(event){
      console.log('searchCidade')
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.autoFilteredCidade = [...this.autoValueCidade];
        }
        else {
          // console.log('vai pesquisar')
          this.produtoService.getPesqCidade().then((data) => {
            // console.log('getPesqCidade')
            // console.log(data)
            this.autoValueCidade = data
            this.autoFilteredCidade = this.autoValueCidade.filter((cidade) => {
              return cidade.descricao.toLowerCase().startsWith(event.query.toLowerCase());
            });
          });

        }
      }, 250);
    },


    enviarEmail(){
      if (!this.produtoData.email) {
        this.$toast.add({severity:'warn', summary: 'Validação', detail:'Formato de e-mail inválido, favor corrigir', life: 3000});
      }else{
        window.open("mailto:"+this.produtoData.email);
      }
    },

    enviarZap(){
      if (!this.produtoData.telefone) {
        this.$toast.add({severity:'warn', summary: 'Validação', detail:'Tamanho do número de telefone inválido, favor corrigir', life: 3000});
      }else{
        window.open('https://api.whatsapp.com/send?phone=5527999722245', '_blank');
      }
    },

    loadBtn(index) {
      this.loadingBtn[index] = true;
      setTimeout(() => this.loadingBtn[index] = false, 1000);
    },

    saveProduto() {
      this.validProduto = true
      if (this.produtoData.nome && this.produtoData.cnpj){
        this.loadingBtnSave = true

        this.produtoService.savessproduto({
          'produto': this.produtoData
        }).then((dataCli) => {
          console.log('savessproduto')
          console.log(dataCli)
          this.loadingBtnSave = false
          if (this.produtoData.id === 0){
            this.produtoData.id = dataCli[0].id
            this.$router.replace({ path: '/produtodetalhe/' + this.produtoData.id }).then(() => {
              this.$toast.add({severity:'success', summary: 'Registro atualizado', detail:'As informações foram atualizadas com sucesso', life: 3000});
            })
          }else{
            this.$toast.add({severity:'success', summary: 'Registro atualizado', detail:'As informações foram atualizadas com sucesso', life: 3000});
          }

        });
      }
    },

    formatDate(value) {
      return value.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },

    formatDecimal(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2});
      return;
    },

    formatCurrency(value) {
      return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
    },
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/styles/badges.scss';
</style>
